export const MAPPING = {
  adressen_felder: "UiAdressen",
  ampel: "UiAmpel",
  antrag_budgetkonto_dimensionen: "UiAntragBudgetkontoDimensionen",
  antrag_budgetkonto_konto: "UiAntragBudgetkontoKonto",
  antrag_score_config: "UiAntragScore",
  antragsteller_rechtsform_zusatz_zweige: "UiAntragstellerRechtsformZusatz",
  anzahl_betreuer_ferien: "UiAnzahlBetreuerFerien",
  auswahl_katalog_eintraege: "UiAuswahlKatalog",
  auswahl_katalog_vorbelegung_zufaellig: "UiBoolean",
  auszahlungsmodi: "UiAuszahlungsmodi",
  auszahlung_modalitaet: "UiAuszahlungModalitaet",
  bestand_zeilen: "UiBestandZeilenFelder",
  dynamische_adressen: "UiDynamischeAdressen",
  dynamische_mehrfachauswahl: "UiDynamischeMehrfachauswahl",
  dynamische_tabelle: "UiDynamischeTabelle",
  dyn_tabelle_objektkatalog_data: "UiDynamischeTabelleObjektkatalogData",
  dyn_tabelle_pdf_transpose: "UiDynamischeTabellePdfTranspose",
  dyn_tabelle_spaltenauswahl_radio: "UiDynamischeTabelleSpaltenAuswahlRadio",
  dynamische_zahlen: "UiDynamischeZahlen",
  elementliste_regeldata: "UiJson",
  elementliste: "UiElementliste",
  elementverzweigung: "UiElementverzweigung",
  gutscheine_erfassen_spalten: "UiGutscheineErfassen",
  kennzahlen_kontext: "UiJson",
  kennzahlen_berechnung: "UiJson",
  perioden_spalten: "UiDynamischeTabellePerioden",
  projektberichte: "UiProjektberichte",
  signal_kosten_regeldata: "UiJson",
  signal_pauschale_config_json: "UiJson",
  themen_auswahl: "UiThemenauswahl",
  wm_kennzahlenconfig: "UiWmKennzahlenConfig",
  wm_regeldataconfig: "UiWmRegelDataConfig",
  zahlungsverkehr_felder: "UiZahlungsverkehr",
  zielgruppen_auswahl: "UiZielgruppenAuswahl",
  // DHW
  dhw_abschreibungsraten: "UiAbschreibungsraten",
  dhw_bewilligungszeitraum_abschreibungsraten: "UiZweckbindung",
  dhw_zuschuss_extra_validierung_regeln: "UiJson",
  // DHW /
};

export const MAPPING_READONLY = {
  adressen_felder: "UiAdressenReadOnly",
  ampel: "UiAmpelReadOnly",
  antrag_budgetkonto_dimensionen: "UiAntragBudgetkontoDimensionenReadOnly",
  antrag_budgetkonto_konto: "UiAntragBudgetkontoKontoReadOnly",
  antrag_score_config: "UiAntragScoreReadOnly",
  antragsteller_rechtsform_zusatz_zweige: "UiAntragstellerRechtsformZusatzReadOnly",
  anzahl_betreuer_ferien: "UiAnzahlBetreuerFerienReadOnly",
  auswahl_katalog_eintraege: "UiAuswahlKatalogReadOnly",
  auswahl_katalog_vorbelegung_zufaellig: "UiBooleanReadOnly",
  auszahlungsmodi: "UiAuszahlungsmodiReadOnly",
  auszahlung_modalitaet: "UiAuszahlungModalitaetReadOnly",
  bestand_zeilen: "UiBestandZeilenFelderReadOnly",
  dynamische_adressen: "UiDynamischeAdressenReadOnly",
  dynamische_mehrfachauswahl: "UiDynamischeMehrfachauswahlReadOnly",
  dynamische_tabelle: "UiDynamischeTabelleReadOnly",
  dyn_tabelle_objektkatalog_data: "UiDynamischeTabelleObjektkatalogDataReadOnly",
  dyn_tabelle_pdf_transpose: "UiDynamischeTabellePdfTransposeReadOnly",
  dyn_tabelle_spaltenauswahl_radio: "UiDynamischeTabelleSpaltenAuswahlRadioReadOnly",
  dynamische_zahlen: "UiDynamischeZahlenReadOnly",
  elementliste_regeldata: "UiJsonReadOnly",
  elementliste: "UiElementlisteReadOnly",
  elementverzweigung: "UiElementverzweigungReadOnly",
  gutscheine_erfassen_spalten: "UiGutscheineErfassenReadOnly",
  kennzahlen_kontext: "UiJsonReadOnly",
  kennzahlen_berechnung: "UiJsonReadOnly",
  perioden_spalten: "UiDynamischeTabellePeriodenReadOnly",
  projektberichte: "UiProjektberichteReadOnly",
  signal_kosten_regeldata: "UiJsonReadOnly",
  signal_pauschale_config_json: "UiJsonReadOnly",
  themen_auswahl: "UiThemenauswahlReadOnly",
  wm_kennzahlenconfig: "UiWmKennzahlenConfigReadOnly",
  wm_regeldataconfig: "UiWmRegelDataConfigReadOnly",
  zahlungsverkehr_felder: "UiZahlungsverkehrReadOnly",
  zielgruppen_auswahl: "UiZielgruppenAuswahlReadOnly",
  // DHW
  dhw_bewilligungszeitraum_abschreibungsraten: "UiZweckbindungReadOnly",
  dhw_abschreibungsraten: "UiAbschreibungsratenReadOnly",
  dhw_zuschuss_extra_validierung_regeln: "UiJsonReadOnly",
  // DHW /
};

export const MODEL = {
  adressen_felder: () => ({}),
  ampel: () => ({
    rot: null,
    gelb: null,
    gruen: null,
  }),
  antrag_score_config: () => ({}),
  anzahl_betreuer_ferien: () => [
    {
      pos: "teilnehmer",
      label: null,
      value: null,
      min: null,
      max: null,
      nachkommastellen: null,
      einheit: null,
      hilfetext: null,
      placeholder: null,
    },
    {
      pos: "teilnehmer_mit_behinderung",
      label: null,
      value: null,
      min: null,
      max: null,
      nachkommastellen: null,
      einheit: null,
      hilfetext: null,
      placeholder: null,
    },
    {
      pos: "betreuer",
      label: null,
      value: null,
      min: null,
      max: null,
      nachkommastellen: null,
      einheit: null,
      hilfetext: null,
      placeholder: null,
    },
  ],
  auswahl_katalog_eintraege: () => [],
  antragsteller_rechtsform_zusatz_zweige: () => [],
  projektberichte: () => [],
  auszahlungsmodi: () => [],
  auszahlung_modalitaet: () => ({}),
  dynamische_adressen: () => [],
  dynamische_mehrfachauswahl: () => [],
  dynamische_tabelle: () => ({}),
  dyn_tabelle_objektkatalog_data: () => [],
  dynamische_zahlen: () => [],
  elementliste: () => [],
  elementverzweigung: () => [],
  gutscheine_erfassen_spalten: () => ({}),
  perioden_spalten: () => ({}),
  themen_auswahl: () => [],
  zahlungsverkehr_felder: () => ({}),
  zielgruppen_auswahl: () => [],
  // DHW
  dhw_abschreibungsraten: () => [],
  dhw_bewilligungszeitraum_abschreibungsraten: () => [],
  // DHW /
};

export const EXTRA_OPTIONS_FOR_TYPE = {
  pruefung_betreuungsverhaeltnis: {
    hideErrors: true,
  },
  anzahl_betreuer_ferien: {
    hideErrors: true,
  },
  auszahlungsmodi: {
    hideErrors: true,
  },
};
