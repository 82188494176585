import CsvDownload from "./global/components/CsvDownload/CsvDownload.vue";
import PuxTableMultipleActionsWorkflowfolgen from "./global/components/PuxTable/PuxTableMultipleActionsWorkflowfolgen/PuxTableMultipleActionsWorkflowfolgen.vue";
import PuxTableRowDefaultSlot from "./global/components/PuxTable/PuxTableRowDefaultSlot/PuxTableRowDefaultSlot.vue";
import PuxTemplateDownload from "./global/components/PuxTemplateDownload/PuxTemplateDownload.vue";
import TableMassActionComponents from "./global/components/PuxTable/TableMassActionComponents";

import UserAPI from "./global/compositionAPI/UserAPI";
import PermissionAPI from "./global/compositionAPI/PermissionAPI";
import SyConfigAPI from "./global/compositionAPI/SyConfigAPI";
import LayoutAPI from "./global/compositionAPI/LayoutAPI";

export default {
  components: {
    CsvDownload,
    PuxTableMultipleActionsWorkflowfolgen,
    PuxTableRowDefaultSlot,
    PuxTemplateDownload,
    ...TableMassActionComponents,
  },
  userApi: UserAPI,
  permissionAPI: PermissionAPI,
  syConfigAPI: SyConfigAPI,
  layoutAPI: LayoutAPI,
  global: {
    project: "proandi",
  },
};
