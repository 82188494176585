import {
  computed,
  toRef,
} from "vue";

export default function DisabledAPI(props) {
  const disabled = toRef(props, "disabled");

  const disabledClass = computed(() => {
    if (disabled.value) {
      return "disabled";
    }

    return undefined;
  });

  return {
    disabledClass,
  };
}
