import AButton from "aloha-vue/src/AButton/AButton";
import Alert from "../../../global/components/Alert/Alert.vue";
import ATranslation from "aloha-vue/src/ATranslation/ATranslation";
import PuxCloak from "../../../global/components/PuxCloak/PuxCloak.vue";
import ShowMore from "../../../global/components/ui/ShowMore/ShowMore.vue";
import TaskLog from "../../../global/components/TaskLog/TaskLog.vue";

import TableMassActionModalMixin from "../../../global/components/PuxTable/TableMassActionModal.mixin";
import HttpMixin from "../../../global/mixins/HttpMixin";

import moment from "moment";
import {
  get,
  isArray,
  isNil,
  map,
  padStart,
  replace,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "DokumentMassActionSammeldokument",
  extraPropParams: [ // TODO: Was ist das?
    {
      id: "format",
      type: "select",
      label: "_LBL_DOKUMENT_MASS_ACTION_SAMMELDOKUMENT_DATEIFORMAT_",
      view: "v",
      required: true,
      default: "zip",
      helpText: `"format": Das Format in dem die Dokumente zusammengefasst werden sollen.`,
      data: [
        { id: "zip", label: "ZIP" },
        { id: "pdf", label: "PDF" },
      ],
    }, {
      id: "dokarten",
      type: "multiselect",
      label: "_LBL_DOKUMENT_MASS_ACTION_SAMMELDOKUMENT_DOKARTEN_",
      searchList: ["bez"],
      view: "v",
      search: true,
      required: true,
      keyLabel: "bez",
      keyId: "pk",
      helpText: `"dokart": Dokarten für die Dokumente der Objekte gesammelt werden sollen.`,
      url: "katalog/?key=dokarten",
    }, {
      id: "dokumentmodul",
      type: "select",
      label: "_LBL_DOKUMENT_MASS_ACTION_SAMMELDOKUMENT_MODUL_",
      searchList: ["bez"],
      view: "v",
      search: true,
      required: true,
      keyLabel: "bez",
      keyId: "id",
      helpText: `"dokumentmodul": Aktives Dokumentenmodul im System, über das die Dokumente geladen werden sollen.`,
      url: "katalog/?key=aktive_dokumentmodule",
    }, {
      id: "dateiname",
      type: "text",
      label: "_LBL_DOKUMENT_MASS_ACTION_SAMMELDOKUMENT_DATEINAME_",
      view: "v",
      required: false,
      helpText: `"dateiname": Name der erstellten Datei. Es können die Platzhalter {jahr} für das Jahr (z.B. "2018"), {monat} für den Monat (z.B. "01") und {tag} für Tag des Monats (z.B. "09"). Die Endung kann weggelassen werden.`,
    }, {
      id: "modalTitle",
      type: "text",
      label: "_LBL_DOKUMENT_MASS_ACTION_SAMMELDOKUMENT_TITEL_",
      view: "v",
      required: false,
      helpText: `"modalTitle": Wenn nicht angegeben, wird '_TXT_DOKUMENT_MASS_ACTION_SAMMELDOKUMENT_MODAL_HEADER_{{anz}}_' verwendet`,
    }, {
      id: "info",
      type: "richtext",
      label: "_LBL_DOKUMENT_MASS_ACTION_SAMMELDOKUMENT_INFOTEXT_",
      view: "v",
      required: false,
      helpText: `"info": Wenn nicht angegeben, wird '_HTML_DOKUMENT_MASS_ACTION_SAMMELDOKUMENT_MODAL_INFO_{{anz}}_' verwendet`,
    },
  ],
  components: {
    AButton,
    Alert,
    ATranslation,
    PuxCloak,
    ShowMore,
    TaskLog,
  },
  mixins: [
    HttpMixin,
    TableMassActionModalMixin,
  ],
  props: {
    format: {
      type: String,
      required: true
    },
    dateiname: {
      type: String,
      required: false,
      default: undefined,
    },
    dokumentmodul: {
      type: String,
      required: true
    },
    dokarten: {
      type: Array,
      required: true
    },
    modalTitle: {
      type: String,
      required: false,
      default: undefined,
    },
    info: {
      type: String,
      required: false,
      default: undefined,
    }
  },
  data() {
    return {
      modalOptions: {
        showCloseButton: true,
        cancelLabel: "_BTN_CLOSE_",
      },
      loading: false,
      requesting: false,
      taskId: undefined,
      result: {
        url: undefined,
        name: undefined,
        gueltigBis: undefined,
        warnings: undefined,
        stillValid: true,
      },
      timeToInvalidExtra: {},
      timeToInvalidPromise: undefined,
      taskError: undefined,
    };
  },
  computed: {
    titleTranslation() {
      return this.modalTitle || "_TXT_DOKUMENT_MASS_ACTION_SAMMELDOKUMENT_MODAL_HEADER_{{anz}}_";
    },
    titleTranslationExtra() {
      return { anz: size(this.rows) };
    },
    infoTranslation() {
      return this.info || "_HTML_DOKUMENT_MASS_ACTION_SAMMELDOKUMENT_MODAL_INFO_{{anz}}_";
    },
    infoTranslationExtra() {
      return { anz: size(this.rows) };
    },
    showTaskLog() {
      return this.taskId && !this.result.url;
    },
    showResult() {
      return !isNil(this.result.url);
    },
    showWarning() {
      return !isNil(this.result.warnings);
    },
    resultWarnings() {
      if (!this.showWarning) {
        return;
      }
      if (!isArray(this.result.warnings)) {
        return [this.result.warnings];
      }
      return this.result.warnings;
    },
    downloadButtonExtra() {
      return {
        dateiname: this.result.name,
      };
    },
    timeToInvalidTranslate() {
      return "_HTML_DOKUMENT_MASS_ACTION_SAMMELDOKUMENT_MODAL_TIME_TO_INVALID_{{minuten}}_{{sekunden}}_{{zeitspanne}}_";
    },
    closeFkt() {
      if (this.showResult && !this.taskError) {
        return this.finish;
      }
      return this.abort;
    }
  },
  created() {
    this.start();
  },
  methods: {
    start() {
      this.requesting = true;
      this.result = {
        url: undefined,
        name: undefined,
        gueltigBis: undefined,
        warning: undefined,
        stillValid: true,
      };
      const TODAY = new Date();
      const FILENAME = replace(
        replace(
          replace(this.dateiname, "{jahr}", TODAY.getFullYear()),
          "{monat}",
          padStart(TODAY.getMonth() + 1, 2, "0")
        ),
        "{tag}",
        padStart(TODAY.getDate(), 2, "0")
      );
      this.postHttp({
        url: `${ this.dokumentmodul }/dokumente/sammeldokument/`,
        data: {
          format: this.format,
          filename: FILENAME,
          dokarten: this.dokarten,
          objects: map(this.rows, row => row.pk),
        }
      }).then(
        response => {
          this.taskId = response.task_id;
        },
        error => {
          if (error && error.data) {
            this.taskError = error.data;
          }
          this.taskError = "_MSG_DOKUMENT_MASS_ACTION_SAMMELDOKUMENT_MODAL_INIT_DEFAULT_ERRROR_";
        }
      ).finally(() => this.requesting = false);
    },
    onSuccess({ tasklog, result }) {
      this.result.name = result.name;
      this.result.warnings = get(tasklog, "tl_data.warnings");
      this.result.gueltigBis = moment(result.cached_until);
      this.result.url = `/api/${ this.dokumentmodul }/dokumente/sammeldokument_retrieve/?task_id=${ this.taskId }`;
      this.timeToInvalidPromise = setInterval(this.updateTimeToInvalid, 1000);
    },
    onFailure({ result }) {
      this.taskError = "_MSG_DOKUMENT_MASS_ACTION_SAMMELDOKUMENT_MODAL_CREATION_DEFAULT_ERRROR_";
      if (result && result.data) {
        this.taskError = result.data;
      }
    },
    updateTimeToInvalid() {
      this.result.stillValid = moment().isBefore(this.result.gueltigBis);
      this.timeToInvalidExtra = {
        minuten: this.result.gueltigBis.diff(moment(), "minutes"),
        sekunden: this.result.gueltigBis.diff(moment(), "seconds"),
        zeitspanne: this.result.gueltigBis.fromNow(true),
      };
      if (!this.result.stillValid && this.timeToInvalidPromise) {
        clearInterval(this.timeToInvalidPromise);
        this.timeToInvalidPromise = undefined;
      }
    }
  }
};
