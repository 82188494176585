import AButton from "aloha-vue/src/AButton/AButton";

import NotificationMixin from "../../../../mixins/NotificationMixin";
import UiValidatedJsonModeMixin from "../UiValidatedJsonModeMixin";

import { initModelFromList, toFormElementFromParameter } from "../../../../functions/mappingForParameterToFormElement";

import {
  gettext,
  replaceText
} from "../../../../functions/utils";

import {
  assign,
  cloneDeep,
  find,
  forEach,
  get,
  isEqual,
  isNil,
  omit,
  size,
  toLower,
} from "lodash-es";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    AButton,
  },
  mixins: [
    NotificationMixin,
    UiValidatedJsonModeMixin,
  ],
  props: {
    nextPos: {
      type: Number,
      required: false,
      default: undefined,
    },
    elements: {
      type: [Object, Array],
      default: undefined,
    },
    elementLabels: {
      type: Object,
      default: undefined,
    },
    idPrefix: {
      type: String,
      default: undefined,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modelLocal: undefined,
      fieldList: undefined,
      errorsLocal: undefined,
    };
  },
  computed: {
    modalHeader() {
      return !isNil(this.model)
      ? "_TXT_UI_VALIDATED_JSON_MODAL_UPDATE_TITLE_{{elementLabel}}_"
      : "_TXT_UI_VALIDATED_JSON_MODAL_CREATE_TITLE_{{elementLabel}}_";
    },

    btnText() {
      return !isNil(this.model)
      ? "_BTN_UI_VALIDATED_JSON_UPDATE_ELEMENT_{{elementLabel}}_"
      : "_BTN_UI_VALIDATED_JSON_ADD_ELEMENT_{{elementLabel}}_";
    },

    translateExtra() {
      return {
        elementLabel: gettext(this.elementLabel)
      };
    },

    anyRequired() {
      let required = false;
      forEach(this.fieldList, el => {
        if (el.required) {
          required = true;
          return false;
        }
      });
      return required;
    },

    modalOptions() {
      return {
        showCloseButton: true,
        required: this.anyRequired,
        list: this.fieldList,
        extra: this.translateExtra
      };
    },

    isDisabledSaveButton() {
      return size(this.errorsLocal) > 0 || (!isNil(this.keyField) && isNil(get(this.modelLocal, this.keyField)));
    },

    currentErrors() {
      const ERR = assign({}, this.errors, this.errorsLocal);
      if (size(ERR) > 0) {
        return ERR;
      }
      return undefined;
    },

    currentErrorsClone() {
      return cloneDeep(this.currentErrors);
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../Modal/Modal.vue").default;
  },
  created() {
    this.initFields();
    this.initModel();
  },
  methods: {
    initFields() {
      const LIST = toFormElementFromParameter({
        obj: this.fields,
        showLabel: true,
        htmlIdPrefix: `modal_${ this.idPrefix }`,
      }).list;
      this.initPosField(LIST);
      this.initKeyField(LIST);
      this.fieldList = LIST;
    },
    initModel() {
      this.modelLocal = cloneDeep(this.model || {});
      const DEFAULT_MODEL = initModelFromList({ list: this.fieldList });
      forEach(DEFAULT_MODEL, (v, id) => {
        this.modelLocal[id] = get(this.modelLocal, id);
        if (isNil(this.modelLocal[id])) {
          this.modelLocal[id] = cloneDeep(v);
        }
      });
    },
    initPosField(fieldList) {
      if (!isNil(this.posField)) {
        const POS_FIELD = find(fieldList, field => field.id === this.posField);
        POS_FIELD.default = this.nextPos;
      }
    },
    initKeyField(fieldList) {
      if (!isNil(this.keyField)) {
        const KEY_FIELD = find(fieldList, field => field.id === this.keyField);
        KEY_FIELD.change = this.onChangeKeyField;
      }
    },
    onChangeKeyField({ currentModel, options }) {
      if (options.typ !== "single_choice") {
        this.modelLocal[this.keyField] = this.tokenize(currentModel);
      }
      const ERR = this.getFieldUniqueError(currentModel, this.keyField);
      if (!isNil(ERR)) {
        this.errorsLocal = assign({}, this.errorsLocal, { [this.keyField]: [ERR] });
      } else if (!isNil(get(this.errorsLocal, this.keyField))) {
        this.errorsLocal = omit(this.errorsLocal, this.keyField);
      }
    },
    tokenize(str) {
      if (isNil(str)) {
        return;
      }
      return toLower(str).replace(/[^a-z0-9]/g, "");
    },
    getFieldUniqueError(currentModel, id) {
      let other;
      forEach(this.elements, (el, key) => {
        if (isEqual(this.model, el)) {
          return;
        }
        if (get(el, id) === currentModel) {
          other = this.elementLabels[key];
          return false;
        }
      });
      if (!other) {
        return;
      }
      return replaceText({
        text: gettext("_ERR_UI_VALIDATED_JSON_MODAL_UNIQUE_{{other}}_"),
        object: { other: other },
      });
    },
    save() {
      if (this.isDisabledSaveButton) {
        return;
      }
      const DATA = cloneDeep(this.modelLocal) || {};
      const KEY_FIELD = find(this.fieldList, field => field.id === this.keyField);
      if (!isNil(this.keyField) && KEY_FIELD.typ !== "single_choice") {
        DATA[this.keyField] = this.tokenize(get(DATA, this.keyField, ""));
      }

      this.close({ model: DATA });
    },
  },
};
