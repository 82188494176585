import {
  computed,
  toRef,
} from "vue";

import AFiltersAPI from "aloha-vue/src/compositionAPI/AFiltersAPI";

export default function DateAPI(props) {
  const note = toRef(props, "note");

  const {
    filterDate,
  } = AFiltersAPI();

  const vinDatum = computed(() => {
    return filterDate(note.value.vin_datum);
  });

  return {
    vinDatum,
  };
}
