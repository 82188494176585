export default function HtmlContainerAPI() {
  const createContainerById = ({ id, ariaHidden }) => {
    let container = document.getElementById(id);
    if (!container) {
      container = document.createElement("div");
      container.id = id;
      container.className = "popup_container";
      if (ariaHidden) {
        container.ariaHidden = "true";
      }

      document.body.appendChild(container);
    }
  };

  return {
    createContainerById,
  };
}
