import HtmlContainerAPI from "./compositionAPI/HtmlContainerAPI";

// @vue/component
export default {
  name: "TheGlobal",
  setup() {
    const {
      createContainerById,
    } = HtmlContainerAPI();

    createContainerById({ id: "tooltip_container", ariaHidden: true });
    createContainerById({ id: "select_container", ariaHidden: false });
  },
  render() {
    return "";
  },
};
