import {
  computed,
  ref,
  toRef,
} from "vue";

export default function InputAPI(props) {
  const inputFile = toRef(props, "inputFile");
  const inputId = toRef(props, "inputId");

  const uploadRef = ref(undefined);

  const inputAriaLabelId = computed(() => {
    return `${ inputId.value }_doc_label`;
  });

  const inputFileLocal = (newFile, oldFile) => {
    inputFile.value({ newFile, oldFile, $refsUpload: uploadRef.value });
  };

  const initAriaLabelForInput = () => {
    uploadRef.value?.$refs?.input?.setAttribute("aria-labelledby", inputAriaLabelId.value);
  };
  
  return {
    initAriaLabelForInput,
    inputAriaLabelId,
    inputFileLocal,
    uploadRef,
  };
}
