// @vue/component
export default {
  props: {
    className: {
      type: String,
      default: "",
    },
    collection: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    data: {
      type: Array,
      required: false,
      default: undefined,
    },
    dependencyValue: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      required: false,
      default: undefined,
    },
    extraStatic: {
      type: Object,
      required: false,
      default: undefined,
    },
    formModel: {
      type: Object,
      default: undefined,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    notLoadList: {
      type: Boolean,
      required: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    // Der Prop ist ein Platzhalter und wird in dieser Komponente nicht verwendet.
    // Er wird nur aus dem Eltern-Element weitergegeben, um die dynamische Struktur der Komponente zu unterstützen.
    idPrefix: {
      type: String,
      required: false,
      default: "",
    },
  },
};
