import {
  onMounted,
} from "vue";

import ATranslation from "aloha-vue/src/ATranslation/ATranslation";
import FileUpload from "vue-upload-component";

import DisabledAPI from "./compositionAPI/DisabledAPI";
import InputAPI from "./compositionAPI/InputAPI";
import SizeAPI from "./compositionAPI/SizeAPI";

// @vue/component
export default {
  name: "UiDocumentDrop",
  components: {
    ATranslation,
    FileUpload,
  },
  props: {
    accept: {
      type: String,
      required: true,
    },
    allowedMimeType: {
      type: String,
      required: true,
    },
    allowedMimeTypeLabel: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Array,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
    inputFile: {
      type: Function,
      required: true,
    },
    inputFilter: {
      type: Function,
      required: true,
    },
    inputId: {
      type: String,
      required: true,
    },
    maximum: {
      type: Number,
      required: false,
      default: 0
    },
    options: {
      type: Object,
      required: true,
    },
    saveDocument: {
      type: Function,
      required: true,
    },
    showMimeType: {
      type: Boolean,
      required: false,
      default: true
    },
    size: {
      type: Number,
      required: true,
    },
    sizeMB: {
      type: Number,
      required: true,
    },
    uploadBtnLabel: {
      type: String,
      required: false,
      default: "_BTN_UI_DOCUMENT_UPLOAD_{{size}}_"
    },
  },
  setup(props) {
    const {
      initAriaLabelForInput,
      inputAriaLabelId,
      inputFileLocal,
      uploadRef,
    } = InputAPI(props);

    const {
      disabledClass,
    } = DisabledAPI(props);

    const {
      sizeExtra,
    } = SizeAPI(props);

    onMounted(() => {
      initAriaLabelForInput();
    });

    return {
      disabledClass,
      inputAriaLabelId,
      inputFileLocal,
      sizeExtra,
      uploadRef,
    };
  },
};
