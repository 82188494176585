import AAlert from "aloha-vue/src/AAlert/AAlert";
import AButton from "aloha-vue/src/AButton/AButton";
import AModal from "aloha-vue/src/AModal/AModal";
import ARequired from "aloha-vue/src/ui/ARequired/ARequired";
import ATranslation from "aloha-vue/src/ATranslation/ATranslation";
import FormElementReadOnly from "../FormElement/FormElementReadOnly/FormElementReadOnly.vue";
import PuxErrors from "../PuxErrors/PuxErrors.vue";

import AutoFocus from "../../directives/AutoFocus";
import Loading from "../../directives/loading";

import {
  filter,
  forEach,
  get,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "Modal",
  components: {
    AAlert,
    AButton,
    AModal,
    ARequired,
    ATranslation,
    FormElementReadOnly,
    PuxErrors,
  },
  directives: {
    Loading,
    AutoFocus,
  },
  props: {
    alertLabels: {
      type: Object,
      default: undefined,
    },
    cancelLabel: {
      type: String,
      required: false,
      default: undefined,
    },
    change: {
      type: Function,
      default: () => {},
    },
    close: {
      type: Function,
      default: undefined,
    },
    closeButtonId: {
      type: String,
      required: false,
      default: undefined,
    },
    confirmOptions: {
      type: Object,
      default: () => ({
        // okClass: "a_btn a_btn_primary",
        // title: "",
        // msg: "",
        // okLabel: "",
        // cancelLabel: "",
        // okCallback: () => {},
        // cancelCallback: () => {},
        // loading: false,
      }),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    dataForm: {
      type: Array,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: [Object, Array],
      default: undefined,
    },
    errorsClone: {
      type: [Object, Array],
      default: () => ({}),
    },
    errorsLabelsOptions: {
      type: Array,
      required: false,
      default: undefined,
    },
    extra: {
      type: Object,
      required: false,
      default: undefined,
    },
    header: {
      type: String,
      required: false,
      default: undefined,
    },
    info: { // Info-I
      type: String,
      required: false,
      default: undefined,
    },
    isCloseButtonHide: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
    },
    model: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    save: {
      type: Function,
      default: undefined,
    },
    selectorClose: {
      type: [String, Array],
      required: false,
      default: undefined,
    },
    selectorCloseIds: {
      type: [String, Array],
      required: false,
      default: undefined,
    },
    showCloseButton: {
      type: Boolean,
      required: false,
    },
    showCloseButtonAloha: {
      type: Boolean,
      required: false,
    },
    size: {
      type: String,
      validator: value => ["small", "large", "xl", "xxl", "fullscreen"].indexOf(value) !== -1,
      default: "large",
    },
    withoutEscape: {
      type: Boolean,
    },
  },
  data() {
    return {
      modalInModalCount: 0,
      statusShowInfo: true,
    };
  },
  computed: {
    headerLocal() {
      return this.header || this.options.header;
    },

    headerExtraLocal() {
      return this.extra || this.options.extra;
    },

    listLocal() {
      return this.dataForm || this.options.list;
    },

    listFiltered() {
      if (this.options.showReadonlyInEdit) {
        return this.listLocal;
      }
      return filter(this.listLocal, item => !item.readonly);
    },

    formElementComponentMap() {
      const MAP = {};
      forEach(this.listLocal, el => {
        MAP[el.id] = el.readonly ? "FormElementReadOnly" : "FormElement";
      });
      return MAP;
    },

    confirmOkLabel() {
      return this.confirmOptions.okLabel || "_BTN_SAVE_";
    },

    confirmCancelLabel() {
      return this.confirmOptions.cancelLabel || "_BTN_CANCEL_";
    },

    cancelLabelLocal() {
      return this.cancelLabel || this.options.cancelLabel || "_BTN_CANCEL_";
    },

    autocomplete() {
      return this.options.autocomplete || "on";
    },

    idCloseButton() {
      if (this.modalInModalCount === 0) {
        return "modal_close";
      }

      return `modal_close_${ this.modalInModalCount }`;
    },

    dependencyValues() {
      const DEPS = {};
      forEach(this.listFiltered, item => {
        if (item.dependency) {
          DEPS[item.id] = get(this.model, item.dependency);
        }
      });
      return DEPS;
    },
  },
  mounted() {
    if ($(".a_backdrop").length) {
      this.modalInModalCount = $(".a_backdrop").length;
    }
  },
  beforeCreate() {
    this.$options.components.FormElement = require("../FormElement/FormElement.vue").default;
  },
  methods: {
    onClose() {
      if (isFunction(this.close)) {
        this.close();
      }
    },

    onInput({ id }) {
      if (this.errorsClone[id]) {
        // eslint-disable-next-line vue/no-mutating-props
        delete this.errorsClone[id];
      }
    },

    saveLocal() {
      if (isFunction(this.save)) {
        this.save();
      }
    },

    hideInfo() {
      this.statusShowInfo = false;
    },
  },
};
