import {
  MODEL,
  EXTRA_OPTIONS_FOR_TYPE,
} from "../../views/Geschaeftsregel/Module/UiComponentsConst";
import {
  MODEL as KF_MODEL,
  EXTRA_OPTIONS_FOR_TYPE as KF_EXTRA_OPTIONS_FOR_TYPE,
} from "../../views/KF/UiComponentsConst";
import FormElementTypeMapping from "../components/FormElement/FormElementTypeMapping";

import {
  setUrlWithParams,
} from "./help";
import {
  assign,
  cloneDeep,
  forEach,
  get,
  isFunction,
  isArray,
  isPlainObject,
  isNil,
  size,
  sortBy,
  isUndefined,
} from "lodash-es";

export const type = {
  single_choice: "select",
  multi_select: "multiselect",
  boolean: "boolean",
  string: "text",
  date: "date",
  datetime: "datetime",
  time: "time",
  decimal: "float",
  integer: "integer",
  richtext: "richtext",
  daymonth: "daymonth",
  iban: "iban",
  regex_config: "regex_config",
  json: "json",
  text: "textarea",
};

export const view = {
  single_choice: "v",
  multi_select: "v",
  string: "v",
  boolean: "v-alt",
  date: "v",
  datetime: "v-alt",
  time: "v",
  decimal: "v",
  integer: "v",
  richtext: "v-alt",
  daymonth: "v-alt",
  iban: "v",
  regex_config: "v",
  json: "v-alt",
  text: "v",
  float: "v",
  currency: "v",
  select: "v",
  multiselect: "v",
  selectordered: "v-alt",
};

export const form_element_view = {
  multiselect: "v",
  select: "v",
  text: "v",
  number: "v",
  integer: "v",
  iban: "v",
};

export const choices = {
  single_choice: true,
  multi_select: true,
  selectordered: true,
};

export const TYPES_LABEL_WITHOUT_FOR = {
  multiselect: true,
  select: true,
};

export const errorLabelGlobalTypes = {
  validated_json: true,
  form_definition: true,
};

export const model = {
  multiselect: () => [],
  selectordered: () => [],
  boolean: item => {
    if (item.triState) {
      return item.defaultValue;
    }
    return item.defaultValue === "true" || item.defaultValue === true || false;
  },
  checkbox: () => [],
  radio: () => [],
  range: () => 0,
  coordinates: () => [],
  daterange: item => ({
    [`${ item.id }_after`]: null,
    [`${ item.id }_before`]: null,
  }),
  daymonth: () => ({
    day: null,
    month: null,
  }),
  dynamische_feldwertliste: () => [],
  tree: item => item.isArray ? [] : {},
};

export function initModelFromList({ list, defaultValue = null }) {
  if (defaultValue === "undefined") {
    defaultValue = undefined;
  }
  const MODEL_DEFAULT = assign(model, MODEL, KF_MODEL);
  const MODEL_LOCAL = {};
  forEach(cloneDeep(list), item => {
    if (!isNil(item.wert)) {
      MODEL_LOCAL[item.id] = item.wert;
    } else if ("default" in item) {
      if (item.type === "multiselect" && size(item.default) === 0) {
        MODEL_LOCAL[item.id] = [];
        return;
      }
      if (isNil(item.default)) {
        if (isFunction(MODEL_DEFAULT[item.type])) {
          MODEL_LOCAL[item.id] = MODEL_DEFAULT[item.type](item);
        } else {
          MODEL_LOCAL[item.id] = defaultValue;
        }
      } else {
        if (isFunction(MODEL_DEFAULT[item.type]) && isArray(MODEL_DEFAULT[item.type](item)) && !isArray(item.default)) {
          MODEL_LOCAL[item.id] = [cloneDeep(item.default)];
        } else {
          MODEL_LOCAL[item.id] = cloneDeep(item.default);
        }
      }
    } else if (isFunction(MODEL_DEFAULT[item.type])) {
      MODEL_LOCAL[item.id] = MODEL_DEFAULT[item.type](item);
    } else {
      MODEL_LOCAL[item.id] = defaultValue;
    }
  });
  return MODEL_LOCAL;
}

export function toRequestBody({ model }) {
  const DATA = {};
  forEach(cloneDeep(model), (item, key) => {
    if (isArray(item)) {
      if (item.length) {
        DATA[key] = item;
      }
    } else if (isPlainObject(item)) {
      forEach(item, (i, k) => {
        if (!isNil(i) && i !== "") {
          DATA[k] = i;
        }
      });
    } else if (!isNil(item) && item !== "") {
      DATA[key] = item;
    }
  });
  return DATA;
}

export function toFormElementFromParameter({
  obj,
  notReadonly,
  showLabel,
  editonly,
  htmlIdPrefix,
  staticOptions,
  staticOptionsForType,
  staticOptionsForId,
}) {
  const OBJECT = {};
  let list = [];
  forEach(cloneDeep(obj), (item, key) => {
    let formElementOptions = item;
    formElementOptions.id = formElementOptions.id || key;
    formElementOptions.label = formElementOptions.label || item.bez;
    if (formElementOptions.type !== "text") { // Workaround für GR "Dynamische Tabellen"
      formElementOptions.type = type[item.typ || item.type] || item.typ || item.type;
    }
    if (formElementOptions.type === "richtext" && formElementOptions.max_length) {
      formElementOptions.max = formElementOptions.max_length;
    }
    if (formElementOptions.type === "select" && formElementOptions.many) {
      formElementOptions.type = "multiselect";
    }
    if (size(formElementOptions.ids) > 0) {
      formElementOptions.komplett = false;
    }
    formElementOptions.default = !isNil(item.default) ? item.default : item.default_value;
    formElementOptions.prio = !isNil(item.prio) ? item.prio : item.pos;
    formElementOptions.helpText = item.help_text;
    formElementOptions.readonly = item.readonly;
    formElementOptions.allowedMimeType = formElementOptions.allowed_mime_type;
    formElementOptions.view = formElementOptions.view || view[formElementOptions.type] || "v-alt";
    if (showLabel) {
      formElementOptions.showLabel = isUndefined(formElementOptions.showLabel) ? true : formElementOptions.showLabel;
    }

    if (item.options) {
      formElementOptions.required = item.options.required;
      formElementOptions.maxLength = item.options.max_length;
      formElementOptions.minLength = item.options.min_length;
      formElementOptions.digits = item.options.decimal_places;
      formElementOptions.maxDigits = item.options.max_digits;
      formElementOptions.maxValue = item.options.max_value;
      formElementOptions.minValue = item.options.min_value;
      formElementOptions.displaymode = item.options.displaymode;
      formElementOptions.dataTranslate = item.options.translate;
      formElementOptions.textbausteingruppen = item.options.textbausteingruppen;
      if (item.options.true_label) {
        formElementOptions.trueLabel = item.options.true_label;
      }
      if (item.options.false_label) {
        formElementOptions.falseLabel = item.options.false_label;
      }
      if (item.options.displaymode === "ordered") {
        item.type = "selectordered";
        item.view = "v-alt";
      }
      if (isNil(formElementOptions.default) && !isNil(item.options.default)) {
        formElementOptions.default = item.options.default;
      }
      if ((item.options.required === false || item.options.allow_null === true) && formElementOptions.type === "select") { // Für "Keine Angabe"
        formElementOptions.deselect = true;
        formElementOptions.placeholder = "_LBL_UI_SELECT_ELEMENT_EMPTY_";
      }
      if (item.options.help_text) {
        formElementOptions.helpText = item.options.help_text;
      }
    }
    if (isUndefined(formElementOptions.required)) {
      formElementOptions.required = true;
    }

    if (item.field_form) {
      formElementOptions.formFields = item.field_form;
    }

    if (item.objektkatalog) {
      formElementOptions.keyId = item.keyId || "pk";
      formElementOptions.keyLabel = item.keyLabel || "bez";
    }

    if (item.katalog) {
      formElementOptions.url = `katalog/?key=${ item.katalog }`;
      formElementOptions.apiSaveId = `katalog_${ item.katalog }`;
      let katalog_filter = item.katalog_filter;
      if (!isNil(get(staticOptionsForId, [formElementOptions.id, "katalog_filter"]))) {
        katalog_filter = staticOptionsForId[formElementOptions.id].katalog_filter;
      }
      if (katalog_filter) {
        formElementOptions.url = setUrlWithParams({
          url: `katalog/?key=${ item.katalog }`,
          params: katalog_filter,
        });
        formElementOptions.urlRetrieve = `katalog/?key=${ item.katalog }`;
        formElementOptions.apiSaveId = formElementOptions.url;
      }
      formElementOptions.keyId = item.keyId || "pk";
      formElementOptions.keyLabel = item.keyLabel || "bez";
      formElementOptions.search = item.search || true;
      formElementOptions.searchList = item.searchList || ["bez"];
    }
    if (item.globalSearch) {
      formElementOptions.searchGlobal = true;
      formElementOptions.searchParameter = item.globalSearch.searchParameter;
      formElementOptions.url = `${ item.globalSearch.url }/`;
      formElementOptions.urlParams = item.globalSearch.urlParams;
      formElementOptions.keyId = item.globalSearch.keyId;
      formElementOptions.keyLabel = item.globalSearch.keyLabel;
    }

    if ((item.choices && item.choices.length) || (item.data && item.data.length)) {
      formElementOptions.data = setDataFromChoices({ choices: item.choices || item.data });
      formElementOptions.keyId = item.keyId || "id";
      formElementOptions.keyLabel = item.keyLabel || "bez";
      formElementOptions.dataTranslate = true;
    }
    if (item.dependency) {
      formElementOptions.dependency = item.dependency;
    }
    if (item.past_days) {
      const DATE = new Date();
      DATE.setDate(DATE.getDate() - item.past_days);
      formElementOptions.notBefore = DATE.toISOString();
    }
    if (item.future_days) {
      const DATE = new Date();
      DATE.setDate(DATE.getDate() + item.future_days);
      formElementOptions.notAfter = DATE.toISOString();
    }
    if (item.true_label) {
      formElementOptions.trueLabel = item.true_label;
    }
    if (item.false_label) {
      formElementOptions.falseLabel = item.false_label;
    }
    if (editonly) {
      formElementOptions.editonly = editonly;
    }
    if (htmlIdPrefix) {
      formElementOptions.htmlId = `${ htmlIdPrefix }_${ formElementOptions.id }`;
    }
    if (item.type === "tree") {
      formElementOptions.fields = toFormElementFromParameter({
        obj: formElementOptions.fields,
        notReadonly,
        showLabel,
        editonly,
        htmlIdPrefix,
        staticOptions,
        staticOptionsForType,
        staticOptionsForId,
      }).list;
    }

    if (EXTRA_OPTIONS_FOR_TYPE[formElementOptions.type]) {
      formElementOptions = assign(formElementOptions, EXTRA_OPTIONS_FOR_TYPE[formElementOptions.type]);
    }
    if (KF_EXTRA_OPTIONS_FOR_TYPE[formElementOptions.type]) {
      formElementOptions = assign(formElementOptions, KF_EXTRA_OPTIONS_FOR_TYPE[formElementOptions.type]);
    }
    if (staticOptions) {
      forEach(staticOptions, (i, k) => {
        formElementOptions[k] = i;
      });
    }
    if (staticOptionsForType && staticOptionsForType[formElementOptions.type]) {
      forEach(staticOptionsForType[formElementOptions.type], (i, k) => {
        formElementOptions[k] = i;
      });
    }
    if (staticOptionsForId && staticOptionsForId[formElementOptions.id]) {
      forEach(staticOptionsForId[formElementOptions.id], (i, k) => {
        formElementOptions[k] = i;
      });
    }
    if (!notReadonly || (notReadonly && !item.readonly)) {
      list.push(formElementOptions);
      OBJECT[key] = cloneDeep(formElementOptions);
    }
    if (isNil(FormElementTypeMapping[formElementOptions.type])) {
      formElementOptions.type = "json";
    }
  });
  list = sortBy(list, ["prio"]);
  return { object: OBJECT, list };
}

function setDataFromChoices({ choices }) {
  const DATA = [];
  if (isPlainObject(choices[0])) {
    return choices;
  }
  forEach(choices, item => {
    if (isArray(item)) {
      DATA.push({
        id: item[0],
        bez: item[1],
        group: item[2],
      });
    }
  });
  return DATA;
}

export function getParameterFromModul({ modul = {}, notReadonly, showLabel } = {}) {
  const ARGUMENT = modul.regel && modul.regel.argument ? modul.regel.argument : {};
  return toFormElementFromParameter({ obj: ARGUMENT, notReadonly, showLabel });
}
