import AButton from "aloha-vue/src/AButton/AButton";

import {
  cloneDeep,
  findIndex,
} from "lodash-es";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    AButton,
  },
  props: {
    model: {
      type: Array,
      default: undefined,
    },
    currentModel: {
      type: Object,
      default: undefined,
    },
    idType: {
      type: String,
      default: () => "string"
    },
    close: {
      type: Function,
      default: () => {},
    },
    selectorClose: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modelLocal: {
        value: undefined,
        label: undefined
      },
    };
  },
  computed: {
    getHeader() {
      return this.currentModel
      ? "_TXT_UI_ANTRAG_SCORE_OPTIONS_LIST_MODAL_UPDATE_TITLE_"
      : "_TXT_UI_ANTRAG_SCORE_OPTIONS_LIST_MODAL_CREATE_TITLE_";
    },
    options() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "value",
            htmlId: `antrag_score_options_list_value`,
            type: this.idType,
            digits: 6,
            label: "_LBL_ANTRAG_SCORE_OPTIONS_VALUE_",
            showLabel: true,
            translate: true,
            required: true,
            view: "v",
          }, {
            id: "label",
            htmlId: `antrag_score_options_list_label`,
            type: "text",
            label: "_LBL_ANTRAG_SCORE_OPTIONS_LABEL_",
            showLabel: true,
            translate: true,
            required: true,
            view: "v",
          },
        ],
      };
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.currentModel) {
        this.modelLocal = cloneDeep(this.currentModel);
      }
    },

    save() {
      if (this.isDisabledSaveButton) {
        return;
      }
      const DATA = cloneDeep(this.modelLocal) || {};

      const MODEL = cloneDeep(this.model) || [];

      if (this.currentModel) {
        const CURRENT_IDX = findIndex(this.model, el => el.value === this.currentModel.value);
        MODEL[CURRENT_IDX] = DATA;
      } else {
        MODEL.push(DATA);
      }
      this.close({ model: MODEL });
    },
  },
};
