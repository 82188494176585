import AButton from "aloha-vue/src/AButton/AButton";

import NotificationMixin from "../../../../../../global/mixins/NotificationMixin";

import {
  assign,
  indexOf,
  without,
} from "lodash-es";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    AButton,
  },
  mixins: [
    NotificationMixin,
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentModel: {
      type: Object,
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      required: true,
    },
    column: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modelLocal: {
        choice: undefined,
      },
      options: {
        showCloseButton: true,
        required: true,
        list: [
          {
            id: "choice",
            label: "_LBL_GR_DYNAMISCHE_TABELLE_PERIODEN_MODAL_UPDATE_CHOICE_",
            type: "radio",
            view: "v-alt",
            required: true,
            data: [
              {
                label: "Unabhängig",
                value: "unabhaengige_spalten",
              },
              {
                label: "Periode",
                value: "periode",
              },
              {
                label: "Abhängig",
                value: "abhaengige_spalten",
              },
            ],
          },
          {
            id: "sortierung",
            label: "_LBL_GR_DYNAMISCHE_TABELLE_PERIODEN_MODAL_UPDATE_IS_SORT_",
            type: "boolean",
            view: "v-alt",
            required: false,
            default: false,
          },
        ],
      },
    };
  },
  computed: {
    modelHasPeriode() {
      return !!this.model.periode;
    },

    isDisabled() {
      return !this.modelLocal.choice;
    },

    isPeriode() {
      return this.modelLocal.choice === "periode";
    },

    isUnabhaengig() {
      return this.modelLocal.choice === "unabhaengige_spalten";
    },

    isAbhaengig() {
      return this.modelLocal.choice === "abhaengige_spalten";
    },

    isSortierung() {
      return this.modelLocal.sortierung;
    },

    data() {
      const DATA = assign({
        periode: undefined,
        sortierung: undefined,
        unabhaengige_spalten: [],
        abhaengige_spalten: [],
      }, this.model);
      DATA.periode = this.isPeriode ? this.column.id : this.model.periode;
      if (!this.isPeriode && DATA.periode === this.column.id) {
        DATA.periode = undefined;
      }
      DATA.sortierung = this.isSortierung ? this.column.id : this.model.sortierung;
      if (!this.isSortierung && DATA.sortierung === this.column.id) {
        DATA.sortierung = undefined;
      }
      DATA.unabhaengige_spalten = without(DATA.unabhaengige_spalten, this.column.id);
      DATA.abhaengige_spalten = without(DATA.abhaengige_spalten, this.column.id);
      if (this.isAbhaengig) {
        DATA.abhaengige_spalten.push(this.column.id);
      }
      if (this.isUnabhaengig) {
        DATA.unabhaengige_spalten.push(this.column.id);
      }
      return DATA;
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    save() {
      this.close({ model: this.data });
    },

    initModel() {
      if (this.model.periode === this.column.id) {
        this.modelLocal.choice = "periode";
      } else if (indexOf(this.model.abhaengige_spalten, this.column.id) !== -1) {
        this.modelLocal.choice = "abhaengige_spalten";
      } else if (indexOf(this.model.unabhaengige_spalten, this.column.id) !== -1) {
        this.modelLocal.choice = "unabhaengige_spalten";
      }
      this.modelLocal.sortierung = this.model.sortierung === this.column.id;
    },
  },
};
