import AButton from "aloha-vue/src/AButton/AButton";
import AModal from "aloha-vue/src/AModal/AModal";
import ATranslation from "aloha-vue/src/ATranslation/ATranslation";
import PuxCloak from "../../global/components/PuxCloak/PuxCloak.vue";
import TheReleaseNotesModalItem from "./TheReleaseNotesModalItem/TheReleaseNotesModalItem.vue";

import DataAPI from "./compositionAPI/DataAPI";

// @vue/component
export default {
  name: "TheReleaseNotesModal",
  components: {
    AButton,
    AModal,
    ATranslation,
    PuxCloak,
    TheReleaseNotesModalItem,
  },
  props: {
    close: {
      type: Function,
      required: true,
    },
    selectorCloseIds: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      loadData,
      releaseNotes,
      statusLoading,
    } = DataAPI();

    loadData();

    return {
      releaseNotes,
      statusLoading,
    };
  },
};
