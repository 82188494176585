import AButton from "aloha-vue/src/AButton/AButton";

import {
  cloneDeep,
} from "lodash-es";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  components: {
    AButton,
  },
  props: {
    dataForFeld: {
      type: Array,
      required: true,
    },
    model: {
      type: Array,
      required: true,
    },
    currentModel: {
      type: Object,
      default: undefined,
    },
    currentIndex: {
      type: Number,
      default: undefined,
    },
    dataForFeldByPK: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modelLocal: {
        thema: null,
        freitext: false,
      },
    };
  },
  computed: {
    getHeader() {
      return this.currentModel ? "Antwortmöglichkeit ändern" : "Antwortmöglichkeit hinzufügen";
    },
    getOptions() {
      return {
        showCloseButton: true,
        required: true,
        list: [
          {
            type: "select",
            id: "thema",
            label: "Thema",
            required: true,
            view: "v",
            keyLabel: "the_kbez",
            keyId: "pk",
            deselect: false,
            data: this.dataForFeld,
            search: true,
            searchList: ["the_kbez"],
          },
          {
            id: "freitext",
            type: "boolean",
            view: "v-alt",
            label: "Mit Freitext",
            required: false,
          },
        ],
      };
    },
    isDisabledSaveButton() {
      return !this.modelLocal.thema;
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.initModel();
  },
  methods: {
    initModel() {
      if (this.currentModel) {
        this.modelLocal = {
          thema: this.currentModel.pk,
          freitext: this.currentModel.freitext || false,
        };
      }
    },
    save() {
      if (this.isDisabledSaveButton) {
        return;
      }
      const DATA = {
        kbez: this.dataForFeldByPK[this.modelLocal.thema].the_kbez,
        bez: this.dataForFeldByPK[this.modelLocal.thema].the_bez,
        freitext: this.modelLocal.freitext,
        pk: this.modelLocal.thema,
      };
      const MODEL = cloneDeep(this.model) || [];

      if (this.currentModel) {
        MODEL.splice(this.currentIndex, 1, DATA);
      } else {
        MODEL.push(DATA);
      }
      this.close({ model: MODEL });
    },
  },
};
