import {
  computed,
  toRef,
} from "vue";

export default function SizeAPI(props) {
  const sizeMB = toRef(props, "sizeMB");

  const sizeExtra = computed(() => {
    return {
      size: sizeMB.value,
    };
  });

  return {
    sizeExtra,
  };
}
