import ATranslation from "aloha-vue/src/ATranslation/ATranslation";

import DateAPI from "./compositionAPI/DateAPI";

// @vue/component
export default {
  name: "TheReleaseNotesModalItem",
  components: {
    ATranslation,
  },
  props: {
    note: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      vinDatum,
    } = DateAPI(props);

    return {
      vinDatum,
    };
  },
};
