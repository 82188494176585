import {
  ref,
} from "vue";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";

export default function DataAPI() {
  const releaseNotes = ref([]);
  const statusLoading = ref(true);

  const {
    getListHttp,
  } = AHttpAPI();

  const loadData = () => {
    getListHttp({
      url: "releasenotes/",
    }).then(
      response => {
        releaseNotes.value = response;
        statusLoading.value = false;
      }
    );
  };

  return {
    loadData,
    releaseNotes,
    statusLoading,
  };
}
