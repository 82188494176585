import UiBelegeExtraFieldFinanzierung from "./ui/UiBelegeExtraFieldFinanzierung/UiBelegeExtraFieldFinanzierung.vue";
import UiBelegeExtraFieldKosten from "./ui/UiBelegeExtraFieldKosten/UiBelegeExtraFieldKosten.vue";
import UiBelegeField from "./ui/UiBelegeField/UiBelegeField.vue";
import UiBelegpruefartFelder from "./ui/UiBelegpruefartFelder/UiBelegpruefartFelder.vue";
import UiSignalKofiPosConfig from "./ui/UiSignalKofiPosConfig/UiSignalKofiPosConfig.vue";
import UiSignalKofiRegeldata from "./ui/UiSignalKofiRegeldata/UiSignalKofiRegeldata.vue";
import UiSignalPeriodenRegeldata from "./ui/UiSignalPeriodenRegeldata/UiSignalPeriodenRegeldata.vue";
import UiZusatzprozent from "./ui/UiZusatzprozent/UiZusatzprozent.vue";
import UiZuschussPeriodenConfig from "./ui/UiZuschussPeriodenConfig/UiZuschussPeriodenConfig.vue";

import UiBelegeFieldReadOnly from "./ui/UiBelegeField/UiBelegeFieldReadOnly/UiBelegeFieldReadOnly.vue";
import UiBelegpruefartFelderReadOnly from "./ui/UiBelegpruefartFelder/UiBelegpruefartFelderReadOnly/UiBelegpruefartFelderReadOnly.vue";
import UiPauschalePeriodenConfig from "./ui/UiPauschalePeriodenConfig/UiPauschalePeriodenConfig.vue";
import UiPauschalePeriodenConfigReadOnly from "./ui/UiPauschalePeriodenConfig/UiPauschalePeriodenConfigReadOnly/UiPauschalePeriodenConfigReadOnly.vue";
import UiSignalPeriodenRegeldataReadOnly from "./ui/UiSignalPeriodenRegeldata/UiSignalPeriodenRegeldataReadOnly/UiSignalPeriodenRegeldataReadOnly.vue";
import UiSignalKofiPosConfigReadOnly from "./ui/UiSignalKofiPosConfig/UiSignalKofiPosConfigReadOnly/UiSignalKofiPosConfigReadOnly.vue";
import UiSignalKofiRegeldataReadOnly from "./ui/UiSignalKofiRegeldata/UiSignalKofiRegeldataReadOnly/UiSignalKofiRegeldataReadOnly.vue";
import UiZusatzprozentReadOnly from "./ui/UiZusatzprozent/UiZusatzprozentReadOnly/UiZusatzprozentReadOnly.vue";
import UiZuschussPeriodenConfigReadOnly from "./ui/UiZuschussPeriodenConfig/UiZuschussPeriodenConfigReadOnly/UiZuschussPeriodenConfigReadOnly.vue";

export default {
  UiBelegeExtraFieldFinanzierung,
  UiBelegeExtraFieldKosten,
  UiBelegeField,
  UiBelegpruefartFelder,
  UiPauschalePeriodenConfig,
  UiSignalKofiPosConfig,
  UiSignalKofiRegeldata,
  UiSignalPeriodenRegeldata,
  UiZusatzprozent,
  UiZuschussPeriodenConfig,
};

export const READ_ONLY = {
  UiBelegeFieldReadOnly,
  UiBelegpruefartFelderReadOnly,
  UiPauschalePeriodenConfigReadOnly,
  UiSignalKofiPosConfigReadOnly,
  UiSignalKofiRegeldataReadOnly,
  UiSignalPeriodenRegeldataReadOnly,
  UiZusatzprozentReadOnly,
  UiZuschussPeriodenConfigReadOnly,
};
