import AMobileAPI from "aloha-vue/src/compositionAPI/AMobileAPI";

import {
  isNil,
} from "lodash-es";

export default function FocusAPI() {
  const {
    isMobileWidth,
  } = AMobileAPI();

  const setFocusTo = selector => {
    const ELEMENT = document.querySelector(selector);
    if (!ELEMENT) {
      return;
    }
    const HAS_TABINDEX = !isNil(ELEMENT.getAttribute("tabindex"));
    if (HAS_TABINDEX) {
      ELEMENT.focus();
    } else {
      ELEMENT.setAttribute("tabindex", "-1");
      ELEMENT.focus({
        preventScroll: true,
      });
      document.documentElement.scrollTop = 0;
      ELEMENT.removeAttribute("tabindex");
    }
  };

  const setFocusToMenu = () => {
    setFocusTo(isMobileWidth.value ? "#btn_a_menu" : "#a_menu");
  };

  const setFocusToMain = () => {
    setFocusTo("#main_content");
  };

  return {
    setFocusToMain,
    setFocusToMenu,
  };
}
