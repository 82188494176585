import AButton from "aloha-vue/src/AButton/AButton";

import OptionsMixin from "./OptionsMixin";
import NotificationMixin from "../../../../../../global/mixins/NotificationMixin";

import {
  initModelFromList
} from "../../../../../../global/functions/mappingForParameterToFormElement";
import {
  gettext,
} from "../../../../../../global/functions/utils";
import {
  assign,
  cloneDeep,
  forEach,
  find,
  size,
  findIndex,
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "UIDynamischeTabelleModalCreateOrUpdate",
  components: {
    AButton,
  },
  mixins: [
    OptionsMixin,
    NotificationMixin,
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
    currentModel: {
      type: Object,
      required: false,
      default: undefined,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modelLocal: {
        label: "",
        id: "",
        required: false,
        type: null,
        styles: [],
      },
    };
  },
  computed: {
    getHeader() {
      return this.currentModel ? "Spalte aktualisieren" : "Spalte hinzufügen";
    },

    getOptions() {
      const LIST_OPTIONS = [
        this.type,
      ];
      LIST_OPTIONS.push(...this.currentOptionsList);
      const REQUIRED_OPTION = find(LIST_OPTIONS, ["id", "required"]);
      if (REQUIRED_OPTION) {
        const READONLY_OPTION = find(LIST_OPTIONS, ["id", "readonly"]);
        if (READONLY_OPTION) {
          REQUIRED_OPTION.change = this.requiredChange;
          READONLY_OPTION.disabled = this.isRequired;
          READONLY_OPTION.helpText = this.isRequired ? gettext("_TXT_DYNAMISCHE_TABELLE_UI_MODAL_OPTION_READONLY_HELP_TEXT_") : "";
        }
      }
      return {
        showCloseButton: true,
        required: true,
        list: LIST_OPTIONS,
      };
    },

    isDisabled() {
      if (!this.modelLocal.type || !this.currentOptionsList.length) {
        return true;
      }
      let statusDisabled = false;
      forEach(this.currentOptionsList, item => {
        if (item.required) {
          if (item.type === "multiselect") {
            if (!this.modelLocal[item.id] || !this.modelLocal[item.id].length) {
              statusDisabled = true;
              return false;
            }
          } else {
            if (!this.modelLocal[item.id]) {
              statusDisabled = true;
              return false;
            }
          }
        }
      });

      return statusDisabled;
    },

    isRequired() {
      return get(this.modelLocal, "required", false);
    },
  },
  beforeCreate() {
    this.$options.components.Modal = require("../../../../../../global/components/Modal/Modal.vue").default;
  },
  created() {
    this.initAllowedMimeTypeList();
    this.initData();
    this.initKatalog();
  },
  methods: {
    initData() {
      if (this.currentModel) {
        const ITEM_FOR_TYPE = find(this.type.data, ["value", this.currentModel.type]) || {};
        this.currentOptionsList = this.setCurrentOptionsList({ options: ITEM_FOR_TYPE.options, init: true });
        this.initModel();
      }
    },

    initModel() {
      const MODEL = initModelFromList({ list: this.currentOptionsList });
      this.modelLocal = assign(MODEL, this.currentModel);
    },

    initKatalog() {
      if (!this.currentModel || !this.currentModel.katalog) {
        return;
      }
      const INDEX_KOMPLETT = findIndex(this.currentOptionsList, ["id", "komplett"]);
      const INDEX_IDS = findIndex(this.currentOptionsList, ["id", "ids"]);
      if (INDEX_KOMPLETT !== -1) {
        this.currentOptionsList[INDEX_KOMPLETT].hideIf = false;
      }
      if (INDEX_IDS !== -1) {
        this.currentOptionsList[INDEX_IDS].hideIf = !!this.currentModel.komplett;
        if (!this.currentModel.komplett) {
          this.changeIdsUrl({ index: INDEX_IDS, katalog: this.currentModel.katalog });
        }
      }
    },

    save() {
      if (this.checkId()) {
        return;
      }
      const MODEL = cloneDeep(this.model);
      const DATA = cloneDeep(this.modelLocal);
      if (!this.currentModel) {
        DATA.pos = size(MODEL);
      } else if (MODEL[this.currentModel.id]) {
        delete MODEL[this.currentModel.id];
      }
      MODEL[DATA.id] = DATA;
      this.close({ model: MODEL });
    },

    checkId() {
      let statusError = false;
      const CURRENT_MODEL = this.currentModel || {};
      forEach(this.model, item => {
        if (item.id === this.modelLocal.id &&
          (item.id !== CURRENT_MODEL.id)) {
          this.addNotification({ text: `Technische ID "${ item.id }" existiert bereits.`, type: "error" });
          statusError = true;
          return false;
        }
      });
      return statusError;
    },

    requiredChange(event) {
      if (event && event.currentModel) {
        this.modelLocal.readonly = false;
      }
    },

    setModel() {
      const MODEL_NEW = initModelFromList({ list: this.currentOptionsList });
      const MODEL_ALT = {
        type: this.modelLocal.type,
        label: this.modelLocal.label,
        required: this.modelLocal.required,
        styles: cloneDeep(this.modelLocal.styles),
        id: this.modelLocal.id,
        pos: this.modelLocal.pos,
      };
      const MODEL_LOCAL = assign(MODEL_NEW, MODEL_ALT);
      if (MODEL_LOCAL.type === "document" && !this.currentModel) {
        MODEL_LOCAL.buttonText = gettext("_BTN_GR_DYNAMISCHE_TABELLE_DOKUMENT_TYP_");
      }
      this.modelLocal = MODEL_LOCAL;
    },
  },
};
