import UiAdressen from "./Adressen/UiAdressen/UiAdressen.vue";
import UiAmpel from "./Ampel/UiAmpel/UiAmpel.vue";
import UiAntragBudgetkontoDimensionen from "./AntragBudgetkonto/UiAntragBudgetkontoDimensionen/UiAntragBudgetkontoDimensionen.vue";
import UiAntragBudgetkontoKonto from "./AntragBudgetkonto/UiAntragBudgetkontoKonto/UiAntragBudgetkontoKonto.vue";
import UiAntragScore from "./AntragScore/UiAntragScore/UiAntragScore.vue";
import UiAntragstellerRechtsformZusatz from "./AntragstellerRechtsformZusatz/UiAntragstellerRechtsformZusatz/UiAntragstellerRechtsformZusatz.vue";
import UiAnzahlBetreuerFerien from "./AnzahlBetreuerFerien/UiAnzahlBetreuerFerien/UiAnzahlBetreuerFerien.vue";
import UiAuswahlKatalog from "./AuswahlKatalog/UiAuswahlKatalog/UiAuswahlKatalog.vue";
import UiAuszahlungsmodi from "./Auszahlungsmodi/UiAuszahlungsmodi/UiAuszahlungsmodi.vue";
import UiAuszahlungModalitaet from "./AuszahlungModalitaet/UiAuszahlungModalitaet/UiAuszahlungModalitaet.vue";
import UiBestandZeilenFelder from "./VertragBestand/UiBestandZeilenFelder/UiBestandZeilenFelder.vue";
import UiDynamischeAdressen from "./DynamischeAdressen/UiDynamischeAdressen/UiDynamischeAdressen.vue";
import UiDynamischeMehrfachauswahl from "./DynamischeMehrfachauswahl/UiDynamischeMehrfachauswahl/UiDynamischeMehrfachauswahl.vue";
import UiDynamischeTabelle from "./DynamischeTabelle/UiDynamischeTabelle/UiDynamischeTabelle.vue";
import UiDynamischeTabelleObjektkatalogData from "./DynamischeTabelle/UiDynamischeTabelleObjektkatalogData/UiDynamischeTabelleObjektkatalogData.vue";
import UiDynamischeTabellePdfTranspose from "./DynamischeTabelle/UiDynamischeTabellePdfTranspose/UiDynamischeTabellePdfTranspose.vue";
import UiDynamischeTabellePdfTransposeReadOnly from "./DynamischeTabelle/UiDynamischeTabellePdfTranspose/UiDynamischeTabellePdfTransposeReadOnly/UiDynamischeTabellePdfTransposeReadOnly.vue";
import UiDynamischeTabellePerioden from "./DynamischeTabellePerioden/UiDynamischeTabellePerioden/UiDynamischeTabellePerioden.vue";
import UiDynamischeTabelleSpaltenAuswahlRadio from "./DynamischeTabelle/UiDynamischeTabelleObjektkatalogData/UiDynamischeTabelleSpaltenAuswahlRadio/UiDynamischeTabelleSpaltenAuswahlRadio.vue";
import UiDynamischeTabelleSpaltenAuswahlRadioReadOnly from "./DynamischeTabelle/UiDynamischeTabelleObjektkatalogData/UiDynamischeTabelleSpaltenAuswahlRadio/UiDynamischeTabelleSpaltenAuswahlRadioReadOnly/UiDynamischeTabelleSpaltenAuswahlRadioReadOnly.vue";
import UiDynamischeZahlen from "./DynamischeZahlen/UiDynamischeZahlen/UiDynamischeZahlen.vue";
import UiElementliste from "./Elementliste/UiElementliste/UiElementliste.vue";
import UiElementverzweigung from "./Elementverzweigung/UiElementverzweigung/UiElementverzweigung.vue";
import UiGutscheineErfassen from "./GutscheineErfassen/UiGutscheineErfassen/UiGutscheineErfassen.vue";
import UiGutscheineErfassenReadOnly from "./GutscheineErfassen/UiGutscheineErfassen/UiGutscheineErfassenReadOnly/UiGutscheineErfassenReadOnly.vue";
import UiProjektberichte from "./Projektberichte/UiProjektberichte/UiProjektberichte.vue";
import UiThemenauswahl from "./Themenauswahl/UiThemenauswahl/UiThemenauswahl.vue";
import UiWmKennzahlenConfig from "./Wirkungsmonitor/UiWmKennzahlenConfig/UiWmKennzahlenConfig.vue";
import UiWmRegelDataConfig from "./Wirkungsmonitor/UiWmRegelDataConfig/UiWmRegelDataConfig.vue";
import UiZahlungsverkehr from "./Zahlungsverkehr/UiZahlungsverkehr/UiZahlungsverkehr.vue";
import UiZielgruppenAuswahl from "./ZielgruppenAuswahl/UiZielgruppenAuswahl/UiZielgruppenAuswahl.vue";

// DHW
import UiZweckbindung from "./DHW/Zweckbindung/UiZweckbindung/UiZweckbindung.vue";
import UiAbschreibungsraten from "./DHW/Zweckbindung/UiAbschreibungsraten/UiAbschreibungsraten.vue";
// DHW /

// // ReadOnly
import UiAdressenReadOnly from "./Adressen/UiAdressen/UiAdressenReadOnly/UiAdressenReadOnly.vue";
import UiAmpelReadOnly from "./Ampel/UiAmpel/UiAmpelReadOnly/UiAmpelReadOnly.vue";
import UiAntragBudgetkontoDimensionenReadOnly from "./AntragBudgetkonto/UiAntragBudgetkontoDimensionen/UiAntragBudgetkontoDimensionenReadOnly/UiAntragBudgetkontoDimensionenReadOnly.vue";
import UiAntragBudgetkontoKontoReadOnly from "./AntragBudgetkonto/UiAntragBudgetkontoKonto/UiAntragBudgetkontoKontoReadOnly/UiAntragBudgetkontoKontoReadOnly.vue";
import UiAntragScoreReadOnly from "./AntragScore/UiAntragScore/UiAntragScoreReadOnly/UiAntragScoreReadOnly.vue";
import UiAntragstellerRechtsformZusatzReadOnly from "./AntragstellerRechtsformZusatz/UiAntragstellerRechtsformZusatz/UiAntragstellerRechtsformZusatzReadOnly/UiAntragstellerRechtsformZusatzReadOnly.vue";
import UiAnzahlBetreuerFerienReadOnly
  from "./AnzahlBetreuerFerien/UiAnzahlBetreuerFerien/UiAnzahlBetreuerFerienReadOnly/UiAnzahlBetreuerFerienReadOnly.vue";
import UiAuswahlKatalogReadOnly
  from "./AuswahlKatalog/UiAuswahlKatalog/UiAuswahlKatalogReadOnly/UiAuswahlKatalogReadOnly.vue";
import UiAuszahlungsmodiReadOnly
  from "./Auszahlungsmodi/UiAuszahlungsmodi/UiAuszahlungsmodiReadOnly/UiAuszahlungsmodiReadOnly.vue";
import UiAuszahlungModalitaetReadOnly from "./AuszahlungModalitaet/UiAuszahlungModalitaet/UiAuszahlungModalitaetReadOnly/UiAuszahlungModalitaetReadOnly.vue";
import UiBestandZeilenFelderReadOnly from "./VertragBestand/UiBestandZeilenFelder/UiBestandZeilenFelderReadOnly/UiBestandZeilenFelderReadOnly.vue";
import UiDynamischeAdressenReadOnly
  from "./DynamischeAdressen/UiDynamischeAdressen/UiDynamischeAdressenReadOnly/UiDynamischeAdressenReadOnly.vue";
import UiDynamischeMehrfachauswahlReadOnly
  from "./DynamischeMehrfachauswahl/UiDynamischeMehrfachauswahl/UiDynamischeMehrfachauswahlReadOnly/UiDynamischeMehrfachauswahlReadOnly.vue";
import UiDynamischeTabelleReadOnly
  from "./DynamischeTabelle/UiDynamischeTabelle/UiDynamischeTabelleReadOnly/UiDynamischeTabelleReadOnly.vue";
import UiDynamischeTabelleObjektkatalogDataReadOnly
  from "./DynamischeTabelle/UiDynamischeTabelleObjektkatalogData/UiDynamischeTabelleObjektkatalogDataReadOnly/UiDynamischeTabelleObjektkatalogDataReadOnly.vue";
import UiDynamischeTabellePeriodenReadOnly
  from "./DynamischeTabellePerioden/UiDynamischeTabellePerioden/UiDynamischeTabellePeriodenReadOnly/UiDynamischeTabellePeriodenReadOnly.vue";
import UiDynamischeZahlenReadOnly
  from "./DynamischeZahlen/UiDynamischeZahlen/UiDynamischeZahlenReadOnly/UiDynamischeZahlenReadOnly.vue";
import UiElementlisteReadOnly from "./Elementliste/UiElementliste/UiElementlisteReadOnly/UiElementlisteReadOnly.vue";
import UiElementverzweigungReadOnly
  from "./Elementverzweigung/UiElementverzweigung/UiElementverzweigungReadOnly/UiElementverzweigungReadOnly.vue";
import UiProjektberichteReadOnly
  from "./Projektberichte/UiProjektberichte/UiProjektberichteReadOnly/UiProjektberichteReadOnly.vue";
import UiThemenauswahlReadOnly from "./Themenauswahl/UiThemenauswahl/UiThemenauswahlReadOnly/UiThemenauswahlReadOnly.vue";
import UiWmKennzahlenConfigReadOnly from "./Wirkungsmonitor/UiWmKennzahlenConfig/UiWmKennzahlenConfigReadOnly/UiWmKennzahlenConfigReadOnly.vue";
import UiWmRegelDataConfigReadOnly from "./Wirkungsmonitor/UiWmRegelDataConfig/UiWmRegelDataConfigReadOnly/UiWmRegelDataConfigReadOnly.vue";
import UiZahlungsverkehrReadOnly from "./Zahlungsverkehr/UiZahlungsverkehr/UiZahlungsverkehrReadOnly/UiZahlungsverkehrReadOnly.vue";
import UiZielgruppenAuswahlReadOnly
  from "./ZielgruppenAuswahl/UiZielgruppenAuswahl/UiZielgruppenAuswahlReadOnly/UiZielgruppenAuswahlReadOnly.vue";

// DHW
import UiZweckbindungReadOnly from "./DHW/Zweckbindung/UiZweckbindung/UiZweckbindungReadOnly/UiZweckbindungReadOnly.vue";
import UiAbschreibungsratenReadOnly from "./DHW/Zweckbindung/UiAbschreibungsraten/UiAbschreibungsratenReadOnly/UiAbschreibungsratenReadOnly.vue";
// DHW /

export default {
  UiAdressen,
  UiAmpel,
  UiAntragScore,
  UiAntragBudgetkontoDimensionen,
  UiAntragBudgetkontoKonto,
  UiAntragstellerRechtsformZusatz,
  UiAnzahlBetreuerFerien,
  UiAuswahlKatalog,
  UiAuszahlungsmodi,
  UiAuszahlungModalitaet,
  UiBestandZeilenFelder,
  UiDynamischeAdressen,
  UiDynamischeMehrfachauswahl,
  UiDynamischeTabelle,
  UiDynamischeTabelleObjektkatalogData,
  UiDynamischeTabellePdfTranspose,
  UiDynamischeTabellePerioden,
  UiDynamischeTabelleSpaltenAuswahlRadio,
  UiDynamischeZahlen,
  UiElementliste,
  UiElementverzweigung,
  UiGutscheineErfassen,
  UiProjektberichte,
  UiThemenauswahl,
  UiWmKennzahlenConfig,
  UiWmRegelDataConfig,
  UiZahlungsverkehr,
  UiZielgruppenAuswahl,
  // DHW
  UiZweckbindung,
  UiAbschreibungsraten,
  // DHW /
};

export const READ_ONLY = {
  UiAdressenReadOnly,
  UiAmpelReadOnly,
  UiAntragBudgetkontoDimensionenReadOnly,
  UiAntragBudgetkontoKontoReadOnly,
  UiAntragScoreReadOnly,
  UiAntragstellerRechtsformZusatzReadOnly,
  UiAnzahlBetreuerFerienReadOnly,
  UiAuswahlKatalogReadOnly,
  UiAuszahlungsmodiReadOnly,
  UiAuszahlungModalitaetReadOnly,
  UiBestandZeilenFelderReadOnly,
  UiDynamischeAdressenReadOnly,
  UiDynamischeMehrfachauswahlReadOnly,
  UiDynamischeTabelleReadOnly,
  UiDynamischeTabelleObjektkatalogDataReadOnly,
  UiDynamischeTabellePdfTransposeReadOnly,
  UiDynamischeTabellePeriodenReadOnly,
  UiDynamischeTabelleSpaltenAuswahlRadioReadOnly,
  UiDynamischeZahlenReadOnly,
  UiElementlisteReadOnly,
  UiElementverzweigungReadOnly,
  UiGutscheineErfassenReadOnly,
  UiProjektberichteReadOnly,
  UiThemenauswahlReadOnly,
  UiWmKennzahlenConfigReadOnly,
  UiWmRegelDataConfigReadOnly,
  UiZahlungsverkehrReadOnly,
  UiZielgruppenAuswahlReadOnly,
  // DHW
  UiZweckbindungReadOnly,
  UiAbschreibungsratenReadOnly,
  // DHW /
};
