export const MAPPING = {
  belege_extra_field_kosten: "ui-belege-extra-field-kosten",
  belege_extra_field_finanz: "ui-belege-extra-field-finanzierung",
  belege_field: "ui-belege-field",
  belegpruefart_felder: "ui-belegpruefart-felder",
  pauschale_perioden_config: "ui-pauschale-perioden-config",
  perioden_zuschuss_config: "ui-zuschuss-perioden-config",
  signal_kofi_regeldata: "ui-signal-kofi-regeldata",
  signal_kofi_posconfig: "ui-signal-kofi-pos-config",
  signal_perioden_regeldata: "ui-signal-perioden-regeldata",
  zusatzprozent_field: "ui-zusatzprozent",
};

export const MAPPING_READONLY = {
  belege_extra_field_kosten: "ui-dynamische-form-definition-read-only",
  belege_extra_field_finanz: "ui-dynamische-form-definition-read-only",
  belege_field: "ui-belege-field-read-only",
  belegpruefart_felder: "ui-belegpruefart-felder-read-only",
  pauschale_perioden_config: "ui-pauschale-perioden-config-read-only",
  perioden_zuschuss_config: "ui-zuschuss-perioden-config-read-only",
  signal_kofi_regeldata: "ui-signal-kofi-regeldata-read-only",
  signal_kofi_posconfig: "ui-signal-kofi-pos-config-read-only",
  signal_perioden_regeldata: "ui-signal-perioden-regeldata-read-only",
  zusatzprozent_field: "ui-zusatzprozent-read-only",
};

export const MODEL = {
  // belege_extra_field_kosten: () => ({ __new__: true }),
  // belege_extra_field_finanz: () => ({ __new__: true }),
};

export const EXTRA_OPTIONS_FOR_TYPE = {
};
